import React from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import informationImage from './volunteer.jpg'; 
import informationImage2 from './volunteer2.jpg'; 
function Volunteer() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage2} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>VOLUNTEER</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          
          <p>As one of our volunteers, you will be joining a highly motivated crew of like-minded people from all over the world.<br/> You will experience the festival from behind the scenes, see your favorite artists when you’re not working, and contribute to the amazing atmosphere that Midgardsblot is famous for.</p>
        <p>Application not  yet open</p> </section>
        <section className="red-section">
        <div className="information-grid2"   >
          <div>
          <h3 className='norse-font'>Who Can Apply?</h3>
          <hr className='sep'></hr>
          <p>You need to be at least 18 years old by the time the festival starts. No previous experience is needed unless it is written in the job description. We welcome volunteers from abroad with open arms, but nationals from countries outside the EU/EEA must ensure they have a valid visa for traveling to and staying in Norway. In exchange for a 4-day festival pass, you have to work at least 2 shifts during the festival (daytime or evening).
</p><p>
We encourage all positive and open-minded people to apply!</p>
          </div>  
          <div>
          <h3 className='norse-font'>Travel and Hospitality</h3>
          <hr className='sep'></hr>
          <p>There will be two options for volunteers, either at Camp Gladheimr(Borre School) or staying at Camp Utgard.</p>
          
          </div>
          <div>
    <h3 className='norse-font'>Before You Apply</h3>
    <hr className='sep'></hr>
    <p>Take the time to read about the different jobs to learn about any specific qualities we’re looking for so that you can select the right teams for you. Check that your availability corresponds with the teams you select. And please make sure that you are in fact able to work on the days you have checked in your application (i.e., vacation time scheduled, dog/cat sitter booked, on-site for your shift), as this will reduce the risk of extra work that occurs with any changes or cancellation after team completion.</p>
</div>

<div>
    <h3 className='norse-font'>The Application</h3>
    <hr className='sep'></hr>
    <p>Submit your data in our online registration form, and make sure that all the information you write is correct. You are free to choose which teams you want to work for and you can select as many teams as you like, but for the sake of distribution you need to select at least 3 in preferred order. All teams have different work shifts throughout the day, and each shift is approximately 8 hours long (including breaks). Please note that the teams will be assigned based on logistics so you may be given a different role/task than what you have chosen.
        Remember that the more available and flexible you are, the easier it is to assign work for you, and a thorough application is helpful to understand your qualities and qualifications. Midgardsblot is not obligated to assign work for everyone applying as a volunteer.
        You have to select three days in the application, but will only need to work two days.
    </p>
</div>

<div>
    <h3 className='norse-font'>The Application Process</h3>
    <hr className='sep'></hr>
    <p>When you have sent your application, you will get an email from us to the email address you entered, confirming that your application is received. When your application is approved, you will receive an email confirming that you have been accepted as part of the Volunteer Crew. You need to confirm that you are still willing and able to work for us; any changes in availability need to be addressed to the Volunteer Manager as soon as possible. Approximately 5 weeks prior to the festival, you will be assigned to a team or role, and you will then receive your schedule containing your work shifts, your Team Leader, and other important information. You have to confirm these shifts by email. Failure to do so may result in you being removed from our list of volunteers, so please pay close attention to the stated deadlines! Note that application approval and work shifts may follow each other shortly. A waiting list will be opened if your application is received after the teams have been completed. If you have any questions regarding the process, please contact Volunteer Manager by email at crew@midgardsblot.no. PS. To make sure our emails arrive, please add crew@midgardsblot.no to your contacts. If our emails do not arrive, please check your spam folder.</p>
</div>

<div>
    <h3 className='norse-font'>Important Rules</h3>
    <hr className='sep'></hr>
    <p>Alcohol and drug consumption during your shifts is strictly prohibited and may result in your wristband being cut. Harassing artists and/or festival guests is not tolerated and may result in your wristband being cut. Sensitive information of any kind acquired through working with artists and/or promoters is not to be shared with other volunteers or the public. Personal contact details of other volunteers are not to be exploited or misused in any way. Crew merchandise is not to be worn when you are off duty. When you have finished your shift and want to see concerts, please remove your Crew shirt before consuming alcohol. The Volunteer Tent with all its benefits is off-limits to volunteers without active work shifts. This includes (but is not limited to) storage space for clothes/bags, food/snacks, and WC facilities. If you have checked in and fail to show up to your agreed shifts without notifying your Team Leader, we will require full payment for your festival pass. Always remember that you are a representative of Midgardsblot Metal Festival when you are working. We expect you to be kind, polite, and help out as best as you can.</p>
</div>

        </div>
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
           
          </div>
        </div> 
        <h3 className='norse-font'>VOLUNTEER TEAMS</h3>
        <div className="information-grid2">
        <div>
    <h3 className='norse-font'>Accreditation</h3>
    <hr className='sep'></hr>
    <p  >This team is located at the first arrival point for all our guests, and therefore the first friendly faces they meet! Working with accreditation, you will be following a set of clear instructions for handing out wristbands and registering sponsors, press, and volunteers. You are polite and accurate in your work, and always ready to pass on information with a smile. It’s great if you are familiar with electronic devices, but you will be given full training onsite.</p>
</div>

<div>
    <h3 className='norse-font'>Backstage</h3>
    <hr className='sep'></hr>
    <p  >You will be preparing the backstage area and dressing rooms so they are ready and well maintained throughout the evening. As a backstage host, you are expected to take good care of the artists, making sure they have what they need from the time they arrive and before they go on stage. We expect you to have a professional attitude towards the artists, and we appreciate that you are responsible and service-minded. Previous experience as an artist host is desirable but not a prerequisite.</p>
</div>

<div>
    <h3 className='norse-font'>Bar</h3>
    <hr className='sep'></hr>
    <p  >The bar team is the perfect fit for you if you love talking to people and enjoy fast-paced work. In this team, you will come into contact with nearly everybody who attends our festival, you will help to take orders, serve drinks, and take payments. You do not need to be a super mathematician (we have tills for that), but it’s a great help if you have experience pouring beers before. You should be happy to be busy and enjoy hectic, fast-paced working hours.</p>
</div>

<div>
    <h3 className='norse-font'>Blot Brigade</h3>
    <hr className='sep'></hr>
    <p  >This team is an opportunity to be a part of our Blót ceremony, leading us and the audience into and out of the sacred ritual. Please note that Blot Brigade is a role that must be combined with a volunteer position in another department. So if you wish to take part in the Blot ceremony as part of the Blot Brigade, please make sure you indicate two other teams you might wish to join.</p>
</div>

<div>
    <h3 className='norse-font'>Camp</h3>
    <hr className='sep'></hr>
    <p  >Your job is being a host for our guest or volunteer camp, ensuring the well-being of our many guests. You will be manning the Camp station and doing inspection rounds. This is a great place to work for people who are sociable, handy, and like being outdoors.</p>
</div>

<div>
    <h3 className='norse-font'>Catering & Sunshine</h3>
    <hr className='sep'></hr>
    <p  >Wanna be everyone’s hero? This team’s job is to make sure that all our amazing volunteers are taken care of, by distributing liquids and sugar, refilling paper towels and hand soap, and generally spreading good vibes. You should be in good health, have good shoes, and enjoy walking in fresh air. It’s great if you’re environmentally conscious, but it’s more important that you can work independently and are able to maintain a positive attitude while doing some physical work.</p>
</div>

<div>
    <h3 className='norse-font'>Checkpoint</h3>
    <hr className='sep'></hr>
    <p  >At an outdoor festival, it is important to have control of the area, especially the entrance and exit points of the venue. Your main job is to check for wristbands to allow guests to enter, and check for correct access to the stage/backstage entrances and the production area. Members of this team also supervise the HC ramp. You are mature, service-minded, and able to follow instructions. You have the ability to say no in a nice and calm manner, and you will be working closely with the professional security guards.</p>
</div>

<div>
    <h3 className='norse-font'>Deco</h3>
    <hr className='sep'></hr>
    <p  >As part of the deco team, you will help our amazing artists to adorn our surroundings, bringing the heart and meaning of the festival to life. You should have a good eye for art and composition as well as be able to be on your feet for long hours. P.s. Some of our decorations are quite heavy, so the deco team is always in need of a few able bodies who may not be particularly artistically gifted, but can help the other members of the team to place the decorations where they should be.</p>
</div>

<div>
    <h3 className='norse-font'>Green Patrol</h3>
    <hr className='sep'></hr>
    <p  >Green patrol is a good fit for those of you who enjoy a full day, with plenty to do but without the high stakes chaos of some of the more extroverted rolls. In green patrol, you will also play a part in reducing our environmental impact by ensuring the correct handling and sorting of trash. You should be someone who likes to meet their step goal even when they’re at a festival, and someone who is happiest working in smaller groups.</p>
</div>

<div>
    <h3 className='norse-font'>Merch</h3>
    <hr className='sep'></hr>
    <p  >Do you have experience selling merch in your hometown? Do you have retail experience? Do you love chatting to people and thrive in a hectic environment? Then the merch team might be a good fit for you. In our merch booth, you will have a chance to meet and talk with thousands of people who are just as passionate about music and Viking history as you. You should be able to work in a busy environment for several hours at a time, and you should enjoy talking to people. This team may be a good fit for our natural extroverts out there.</p>
</div>

<div>
    <h3 className='norse-font'>Rigg</h3>
    <hr className='sep'></hr>
    <p  >If you are a handy individual who is up for some physically demanding work before and/or after the festival, the rigg team might be a good fit for you. In this team, you will have a chance to be a part of bringing the festival to life from an empty field and returning it to its natural state after the final show. You should be happy to be busy and like both hectic and quiet hours, and you should be prepared for a bit of hard work - we promise it is worth it though! Places on shifts before and after the festival are very limited. If you choose rigg as an option, please make sure you choose two other options for teams that work during the festival days.</p>
</div>

<div>
    <h3 className='norse-font'>Runner</h3>
    <hr className='sep'></hr>
    <p  >As a festival runner, you are literally saving the day as a problem solver! Your duty is to act when there is something to pick up or buy (equipment, food, etc.) for any teams, and we’re looking for versatile people who are not afraid of challenges or multitasking. Maybe you are from Horten (or nearby) so you know your way around the area? You need to have a driver’s license for this job.</p>
</div>

<div>
    <h3 className='norse-font'>Stagehands</h3>
    <hr className='sep'></hr>
    <p  >Stagehands work backstage with loading the artists’ equipment in/out of cars and on/off stage as well as building risers. This job is physically demanding, and you should be strong, handy, and in good health for lifting and carrying boxes. You need to be reliable, able to take orders, and work fast. It is also important that you behave in a professional manner towards artists and crew. It is not essential, but it is preferable that you have previous experience as a stagehand (either as a volunteer or as a professional). Please provide details of your experience or why you think you would be a good fit despite having no previous experience, in the application.</p>
</div>

<div>
    <h3 className='norse-font'>Guerilla</h3>
    <hr className='sep'></hr>
    <p  >If you are not sure what you want to apply for, or you want to contribute a little extra, you can apply as a Guerilla. Here you will have the opportunity to test out a number of different work tasks at the festival and be the one who comes in and saves the day if there is anyone needing an extra pair of hands and a cool head. You should be happy to be busy and like both hectic and quiet hours.</p>
</div>

<div>
    <h3 className='norse-font'>Team Assistant</h3>
    <hr className='sep'></hr>
    <p  >This is a practical job where you’ll assist different Teams/Team Leaders, and the duties vary from which team you are placed in. You may also be put in a specific role, such as Conference Assistant, Press Tent Assistant, or Coordinator. We appreciate team players who can work independently, find solutions, and take responsibility. If you have leadership qualities or previous experience with festival coordination, we would like to hear from you!</p>
</div>

<div>
    <h3 className='norse-font'>Transport</h3>
    <hr className='sep'></hr>
    <p  >Do you have experience as a professional driver, or do you work with customer service? That gives you a great advantage, but it’s more important to have a positive attitude and to be reliable. You will be driving the artists from the pick-up point to the drop-off point at specific times and thus need to be polite and professional in your contact with the artists. You obviously need a valid driver’s license class B, and you should be able to handle a minivan (up to 9 seats).</p>
</div>

        </div>
      
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Volunteer;
