import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';
import informationImage from './shuttle.jpg'; 


function Shuttle() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>SHUTTLE</h1>
          </div>
        </div>
      </header>
      <main>
    
        <section className="lineup-section">
        <h1 className='norse-font'>SHUTTLE</h1>
       <p>Shuttle info will be available closer to the festival.</p>
       </section>
      
      </main>
      <Footer />
    </div>
  );
}

export default Shuttle;
