import React, { useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import './Home.css';
import poster from './blind_bird.jpg';


function Home() {
  useEffect(() => {
    const countdown = () => {
      const targetDate = new Date('2025-08-13T14:00:00+02:00').getTime();
      const now = new Date().getTime();
      const distance = targetDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      document.getElementById('countdown').innerHTML =
        days + 'D ' + hours + 'H ' + minutes + 'M ' + seconds + 'S ';

      if (distance < 0) {
        clearInterval(intervalId);
        document.getElementById('countdown').innerHTML = 'The event has started!';
      }
    };

    const intervalId = setInterval(countdown, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="Home">
      <Navbar />
      <header className="Home-header">
        <div className="hero-section">
          <video autoPlay muted loop className="hero-video">
            <source src="https://www.midgardsblot.no/img/video/homepage.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="hero-overlay">
            
            <div className="countdown-timer">
              <img alt="border" src="https://midgardsblot.no/resources/assets/border.png"/>
              <h2 className='norse-font' id="countdown"> </h2>
              <img alt="border" src="https://midgardsblot.no/resources/assets/border.png"/>
              
            </div>
            <p className='norse-font'>Join us for mysteries of the past, melodies of the present, and dive into the heart of Midgardblot with us!</p>
            <p>Blind bird tickets in sale now!</p>
            <a href="https://www.ticketmaster.no/artist/midgardsblot-tickets/1197464?language=en-us" rel="noopener noreferrer" target="_blank" className="norse-font-bold cta-button">Get your tickets!</a>
            </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <div className="lineup-grid">
           
            <div>
              <h3 className="norse-font">WELCOME HOME!</h3>
              <hr className="sep"></hr>
              <p>We are beyond grateful to every single one of you who joined us for this year’s Midgardsblot. The Norns wove your passion, energy, and spirit into the fabric of our celebration, creating an atmosphere brimming with magic and wonder.</p>

<p>Together, we honored our shared love for music, art, mythology, and the community we cherish. Every moment we spent together will echo in our memories.</p>

<p>Midgardsblot is more than just a festival; it is an experience we share together!</p>

<p>And as we still reflect on these unforgettable days, we look towards the next chapter of the Midgardsblot Saga.</p>

<p><strong>Mark your calendars:</strong> August 13th-16th 2025, is when the gates of Midgard will open once more!</p>

<p>Until we meet again,<br/>
Love, honor, and peace,<br/>
Midgardsblot</p>

            </div>
            <div>
              <img style={{ width: '100%' }} src={poster} alt="Festival Lineup"></img>
           
            </div>
            <div>
              <h3 className="norse-font">CREW LOVE IS TRUE LOVE</h3>
              <hr className="sep"></hr>
              <p>Another unforgettable year at Midgardsblot, and it's all because of you. Your relentless dedication and hard work brought this magical gathering to life once again. From the very first setup to the final farewell, each of you—crew, volunteers, and beyond—crafted something truly extraordinary.</p>

<p>Whether you were building the festival grounds, quenching the thirst of the bloters, capturing every memorable moment, keeping everyone safe, or creating the perfect vibe with lights and sound—each and every one of you played a vital role in bringing this world to life. Your efforts make Midgardsblot more than just a festival; you make it a sanctuary for our community, where memories are created and both old and new connections grow.</p>

<p>This incredible experience wouldn’t be possible without your dedication. Thank you for being the heartbeat of Midgardsblot. Let’s continue building this amazing saga, uniting people through music, culture, and community.</p>

<p>Together, we make Midgardsblot truly one of a kind.</p>

            </div>
          </div>
        </section>
        <section className='red-section'>
        
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
