import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';
import informationImage from './children.jpg'; 

function Children() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>CHILDREN</h1>
          </div>
        </div>
      </header>
      <main>
        <section className="lineup-section">
          <h1 className='norse-font'>Kids are welcome at Midgardsblot!</h1>
          <p>Kids below the age of 12 can come to Midgardsblot in the company of an adult with a valid ticket for the festival (as long as the festival is not sold out).<br/><br/> Youth aged 12-17 will need to buy their own ticket. <br/><br/>
          Youth under the age of 16 have to be accompanied by a guardian.</p>
          <p>Application will open up on a later date</p>
        </section>
        <section className="red-section">
          
          <h1 className='norse-font'>Information</h1>
          <p style={{ fontSize: '0.8em' }}>Kids below the age of 12 can come to Midgardsblot in the company of an adult with a valid ticket for the festival (as long as the festival is not sold out). Youth aged 12-17 will need to buy their own ticket. Youth under the age of 16 have to be accompanied by a guardian.</p>
          <p style={{ fontSize: '0.8em' }}>To make sure everyone is safe and sound, we need you to register your kids upon arrival in our ticket-to-wristband/accreditation stand at the entrance of the festival in case you get away from each other. We need the kids' first name and contact info for the kids' guardian. The kid will also get a wristband with the contact info of their guardian on it.</p>
          <p style={{ fontSize: '0.8em' }}>We expect you as an adult to be sober at the festival as long as you have your kids with you.</p>
          <p style={{ fontSize: '0.8em' }}>Note that you need to be 18 years old to stay in the camp, no exceptions.</p>
          <p style={{ fontSize: '0.8em' }}>When you arrive at the festival area you will need to register your child at accreditation.</p>
          <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/LUyQHgbkatI"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Children;
