import React from 'react';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import './../Information.css';

import informationImage from './ticket.jpeg'; 



function Ticket() {
  return (
    <div className="Information">
      <Navbar />
      <header className="Information-header">
        <div className="image-text-container">
          <img src={informationImage} alt="Information" className="information-image" />
          <div className="information-text">
            <h1 className='norse-font'>TICKET INFORMATION</h1>
          </div>
        </div>
      </header>
      <main>
    
        <section className="lineup-section">
        
        <p>All tickets for Midgardsblot can be found on sale here: </p>
        <a href="https://www.ticketmaster.no/artist/midgardsblot-tickets/1197464?language=en-us" rel="noreferrer" target="_BLANK" className="read-more-button">Ticketmaster</a> 
       
</section>
<section className="info-section">
<div className="info-grid">
  <div>          
    <h3 className='norse-font'>BLIND BIRD FESTIVAL PASS</h3>
      <hr className='sep'></hr>
      <p>Access to all 4 festival days</p>
      <p style={{ fontSize: '0.8em' }}>NOK 2,999.00</p>
        <a href="https://www.ticketmaster.no/event/4-day-festival-pass-midgardsblot-metalfestival-2024-tickets/724511?language=en-us" rel="noreferrer" target="_BLANK" className="read-more-button">Ticketmaster</a> 
      </div>
     
      </div>
</section>
<section className="lightred-section">
<p>Bring a valid photo-ID with you! All festival guests with a valid ID of the age of 18 or over will receive a wristband as a proof of your access to buy alcohol in the festival area. People without a valid ID will not be able to buy alcoholic beverages in the festival area.</p>

<p>Camp Utgard has a strict 18-year age limit. You must bring and show a valid ID upon check-in.</p>

<p>The use of false credentials/fake IDs will be prosecuted, the police will be summoned, and you will be denied access to the festival.</p>

<p>Make sure your tickets can be scanned on your phone before coming to the festival - if you have a broken screen, etc., we cannot guarantee we can scan your ticket. Please bring a print of your ticket if you are not sure we will be able to scan your ticket.</p>

</section>
      </main>
      <Footer />
    </div>
    
  );
}

export default Ticket;
